import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { truck_content } from "../../../../actions/yardActions/TruckAction";
import truck_1 from "../../../../images/truck_1.jpg";
import truck_2 from "../../../../images/truck_2.jpg";
import truck_3 from "../../../../images/truck_3.jpg";

const EquipmentImg = () => {
    const  id =1;
    const dispatch = useDispatch();
    const single_truck = useSelector((state) => state.truck.single_truck);
    const [state, setState] = useState("");
    const [img, setImg] = useState(false);
    useEffect(() => {
        if (single_truck != null) {
            setState(single_truck);
        }
        dispatch(truck_content(id));
    }, [single_truck]);
    const imgHandler = (number) => {
        setImg(number)
    }
    
    return (
        <>
            <div className="equ_images">
                <div className="equ_big_img">
    
                    {(() => {
                        if (img == 1) {
                            return <img src={state.img} alt="equipment image1" />
                        }
                        else if (img == 2) {
                            return <img src={truck_1} alt="equipment image2" />
                        }
                        else if (img == 3) {
                            return <img src={truck_2} alt="equipment image3" />
                        }
                        else if (img == 4) {
                            return <img src={truck_3} alt="equipment image4" />
                        } else {
                            return <img src={state.img} alt="equipment image1" />
                        }
                    })()}
                </div>
                <div className="equ_small_img">
                    <img className={img == 1 ? ("activeTruck") : ("")} id={img ? ("") : ("defaltImge")} onClick={() => { imgHandler(1) }} src={state.img} alt="equipment image1" />
                    <img className={img == 2 ? ("activeTruck") : ("")} onClick={() => { imgHandler(2) }} src={truck_1} alt="equipment image2" />
                    <img className={img == 3 ? ("activeTruck") : ("")} onClick={() => { imgHandler(3) }} src={truck_2} alt="equipment image3" />
                    <img className={img == 4 ? ("activeTruck") : ("")} onClick={() => { imgHandler(4) }} src={truck_3} alt="equipment image4" />
                </div>
            </div>
        </>
    )
}
export default EquipmentImg;
